export const blue000 = 'hsl(256, 100%, 27%)';
export const blue100 = 'hsl(256, 86%, 40%)';
export const blue200 = 'hsl(245, 79%, 52%)';
export const blue300 = 'hsl(245, 92%, 60%)';
export const blue400 = 'hsl(243, 94%, 66%)';
export const blue500 = 'hsl(243, 94%, 70%)';
export const blue600 = 'hsl(240, 95%, 76%)';
export const blue700 = 'hsl(238, 94%, 81%)';
export const blue800 = 'hsl(238, 100%, 88%)';
export const blue900 = 'hsl(240, 100%, 95%)';

export const green000 = 'hsl(170, 97%, 15%)';
export const green100 = 'hsl(168, 80%, 23%)';
export const green200 = 'hsl(166, 72%, 28%)';
export const green300 = 'hsl(164, 71%, 34%)';
export const green400 = 'hsl(162, 63%, 41%)';
export const green500 = 'hsl(160, 51%, 49%)';
export const green600 = 'hsl(158, 58%, 62%)';
export const green700 = 'hsl(156, 73%, 74%)';
export const green800 = 'hsl(154, 75%, 87%)';
export const green900 = 'hsl(152, 68%, 96%)';

export const grey000 = 'hsl(209, 61%, 16%)';
export const grey100 = 'hsl(211, 39%, 23%)';
export const grey200 = 'hsl(209, 34%, 30%)';
export const grey300 = 'hsl(209, 28%, 39%)';
export const grey400 = 'hsl(210, 22%, 49%)';
export const grey500 = 'hsl(209, 23%, 60%)';
export const grey600 = 'hsl(211, 27%, 70%)';
export const grey700 = 'hsl(210, 31%, 80%)';
export const grey800 = 'hsl(212, 33%, 89%)';
export const grey900 = 'hsl(210, 36%, 96%)';

export const lightBlue000 = 'hsl(204, 96%, 27%)';
export const lightBlue100 = 'hsl(203, 87%, 34%)';
export const lightBlue200 = 'hsl(202, 83%, 41%)';
export const lightBlue300 = 'hsl(201, 79%, 46%)';
export const lightBlue400 = 'hsl(199, 84%, 55%)';
export const lightBlue500 = 'hsl(197, 92%, 61%)';
export const lightBlue600 = 'hsl(196, 94%, 67%)';
export const lightBlue700 = 'hsl(195, 97%, 75%)';
export const lightBlue800 = 'hsl(195, 100%, 85%)';
export const lightBlue900 = 'hsl(195, 100%, 95%)';

export const red000 = 'hsl(348, 94%, 20%)';
export const red100 = 'hsl(350, 94%, 28%)';
export const red200 = 'hsl(352, 90%, 35%)';
export const red300 = 'hsl(354, 85%, 44%)';
export const red400 = 'hsl(356, 75%, 53%)';
export const red500 = 'hsl(360, 83%, 62%)';
export const red600 = 'hsl(360, 91%, 69%)';
export const red700 = 'hsl(360, 100%, 80%)';
export const red800 = 'hsl(360, 100%, 87%)';
export const red900 = 'hsl(360, 100%, 95%)';

export const yellow000 = 'hsl(15, 86%, 30%)';
export const yellow100 = 'hsl(22, 82%, 39%)';
export const yellow200 = 'hsl(29, 80%, 44%)';
export const yellow300 = 'hsl(36, 77%, 49%)';
export const yellow400 = 'hsl(42, 87%, 55%)';
export const yellow500 = 'hsl(44, 92%, 63%)';
export const yellow600 = 'hsl(48, 94%, 68%)';
export const yellow700 = 'hsl(48, 95%, 76%)';
export const yellow800 = 'hsl(48, 100%, 88%)';
export const yellow900 = 'hsl(49, 100%, 96%)';
