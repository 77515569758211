import Typography from 'typography';
import Theme from 'typography-theme-github';
import { blue500, yellow300, yellow500 } from './colours';

Theme.overrideThemeStyles = () => {
  return {
    a: {
      color: blue500,
    },
    'a.gatsby-resp-image-link': {
      boxShadow: `none`,
    },
    h1: {
      borderBottom: 'none',
    },
    h2: {
      borderBottom: 'none',
    },
    hr: {
      opacity: 0.5,
    },
    strong: {
      fontWeight: 700,
    },
    blockquote: {
      color: yellow300,
      borderColor: yellow500,
      fontStyle: 'italic',
      margin: '1rem 0',
    },
  };
};

delete Theme.googleFonts;

Theme.headerFontFamily = ['Montserrat', 'sans-serif'];
Theme.bodyFontFamily = ['Muli', 'sans-serif'];
Theme.baseFontSize = '18px';

const typography = new Typography(Theme);

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
